<template>
  <b-card>
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <h4 v-if="history.length == 0">No Fee History</h4>
      <app-timeline-item
        v-for="(item, ind) in history"
        :key="ind"
        :variant="item.fees.billID <= 0 ? 'danger' : 'info'"
      >
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6 v-if="item.fees.billID <= 0" class="text-capitalize">
            {{ item.fees.status }}
          </h6>
          <h6 v-else>Slip No# {{ item.fees.billID }}</h6>
          <small class="text-muted">
            {{
              new Date(item.fees.rcvdDate).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}</small
          >
        </div>
        <p class="font-weight-bold mb-50">
          {{ item.feesType.feeType }} -
          <span>
            {{ item.fees.billID == 0 ? item.fees.exempted : item.fees.rcvd }}
          </span>
        </p>
        <feather-icon
          v-if="item.fees.billID > 0"
          icon="PrinterIcon"
          size="20"
          class="cursor-pointer"
          @click="printSlip(item.fees.billID, item.fees.campusID)"
        />
        <!-- <p class="mb-0 cursor-pointer">
         <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          /> 
          <span class="align-bottom">invoice.pdf</span> 
        </p> -->
      </app-timeline-item>
      <!-- <app-timeline-item variant="warning">
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6>Client Meeting</h6>
          <small class="text-muted">45 min ago</small>
        </div>
        <p>Project meeting with john @10:15am.</p>
        <b-media>
          <template #aside>
            <b-avatar :src="require('@/assets/images/avatars/8-small.png')" />
          </template>
          <h6>John Doe (Client)</h6>
          <p class="mb-0">CEO of Infibeam</p>
        </b-media>
      </app-timeline-item>
      <app-timeline-item
        variant="info"
        title="Create a new project for client"
        subtitle="Add files to new design folder"
        time="2 days ago"
      /> -->
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar, VBTooltip } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    VBTooltip,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    history: {
      type: Array,
      required: true,
    },
    duesTotal: {
      type: Number,
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      color: ["primary", "warning", "info", "success", "secondary", "danger"],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
    };
  },
  methods: {
    printSlip(id, cID) {
      let url =
        `https://${this.reportDomain}.myskool.app/Fee/Slip?dbb=` +
        this.$store.state.userData.db +
        "&bID=" +
        id +
        "&cID=" +
        cID +
        "&rem=" +
        this.duesTotal;
      window.open(url, "_blank");
    },
  },
};
</script>

<style></style>
